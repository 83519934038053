import {isEscapeKey} from './utils/common.js';
import {
  addSkrollLock,
  removeSkrollLock,
} from './skroll-lock.js';

const modalWindowElement = document.querySelector('.modal');
const modalCloseButton = modalWindowElement.querySelector('.modal__close-button');

const onDocumentKeydown = (evt) => {
  if (isEscapeKey(evt)) {
    evt.preventDefault();
    closeModal();
  }
};

const onOutsideModalWindow = (evt) => {
  if (evt.target === evt.currentTarget) {
    closeModal();
  }
};

const onModalCloseButton = (evt) => {
  evt.preventDefault();
  closeModal();
};

function openModal() {
  modalWindowElement.classList.remove('modal--close');
  document.addEventListener('keydown', onDocumentKeydown);
  modalCloseButton.addEventListener('click', onModalCloseButton);
  modalWindowElement.addEventListener('click', onOutsideModalWindow);
  addSkrollLock();
}

function closeModal() {
  modalWindowElement.classList.add('modal--close');
  document.removeEventListener('keydown', onDocumentKeydown);
  modalCloseButton.removeEventListener('click', onModalCloseButton);
  modalWindowElement.removeEventListener('click', onOutsideModalWindow);
  removeSkrollLock();
}

export {openModal};
