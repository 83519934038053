import {
  addSkrollLock,
  removeSkrollLock,
} from './skroll-lock.js';

const headerElement = document.querySelector('.header');
const siteListItemsWhithDop = headerElement.querySelectorAll('.site-list__item--dop');

const DESKTOP_WIDTH = 1380;
const TABLET_WIDTH = 768;

class siteListDopOnOff {
  #item = null;
  #mainLink = null;
  #dopSiteList = null;
  #dopSiteListMask = null;

  constructor(siteListItemWhithDop) {
    this.#item = siteListItemWhithDop;
    this.#mainLink = siteListItemWhithDop.querySelector('a');
    this.#dopSiteList = siteListItemWhithDop.querySelector('.site-list__dop-site-list');
    this.#dopSiteListMask = siteListItemWhithDop.querySelector('.site-list__dop-mask');

    this.init();
  }

  init() {
    if (window.innerWidth >= DESKTOP_WIDTH) {
      this._restoreHandlers();
      return;
    } else {
      this._removeHandlers();
    }

    if (window.innerWidth >= TABLET_WIDTH && window.innerWidth < DESKTOP_WIDTH) {
      this.#item.addEventListener('click', this.#onLinkClickHandler);
    }
  }

  _restoreHandlers() {
    this.#item.addEventListener('click', this.#onLinkClickHandler);
    this.#mainLink.addEventListener('mouseover', this.#showDopSiteListHandler);
    this.#dopSiteList.addEventListener('mouseover', this.#showDopSiteListHandler);
  }

  _removeHandlers() {
    this.#mainLink.removeEventListener('mouseover', this.#showDopSiteListHandler);
    this.#dopSiteList.removeEventListener('mouseover', this.#showDopSiteListHandler);
  }

  #showDopSiteList = () => {
    this.#dopSiteList.classList.add('site-list__dop-site-list--show');
    this.#dopSiteListMask.classList.add('site-list__dop-mask--show');
  };

  #hiddenDopSiteList = () => {
    this.#dopSiteList.classList.remove('site-list__dop-site-list--show');
    this.#dopSiteListMask.classList.remove('site-list__dop-mask--show');
  };

  #hiddenDopSiteListHandler = () => {
    removeSkrollLock();
    this.#hiddenDopSiteList();
  };

  #showDopSiteListHandler = () => {
    addSkrollLock();
    this.#showDopSiteList();
    this.#mainLink.addEventListener('mouseout', this.#hiddenDopSiteListHandler, {
      once: true,
    });
    this.#dopSiteList.addEventListener('mouseout', this.#hiddenDopSiteListHandler, {
      once: true,
    });
  };

  #onLinkClickHandler = (evt) => {
    if (evt.target.tagName === 'A') {
      this.#hiddenDopSiteListHandler();
    }

    if (evt.target.dataset.lableId) {
      const lable = document.querySelector(`#${evt.target.dataset.lableId}`);
      lable.click();
      //lable.checked = true; // EventListener('change' не реагирует на это)
    }
  };
}

const siteListDopPresenters = new Map();

siteListItemsWhithDop.forEach((siteListItemWhithDop) => siteListDopPresenters.set(siteListDopPresenters.size + 1, new siteListDopOnOff(siteListItemWhithDop)));

const onWindowResize = () => siteListDopPresenters.forEach((presenter) => presenter.init());
window.addEventListener('resize', onWindowResize);
