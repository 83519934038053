const headerElement = document.querySelector('.header');

const getScrollWidth = () => window.innerWidth - document.documentElement.clientWidth;

const addSkrollLock = () => {
  headerElement.style.marginLeft = `-${getScrollWidth()}px`;
  document.body.style.marginLeft = `-${getScrollWidth()}px`;
  document.body.classList.add('scroll-lock');
};

const removeSkrollLock = () => {
  headerElement.style = null;
  document.body.style = null;
  document.body.classList.remove('scroll-lock');
};

export {
  addSkrollLock,
  removeSkrollLock,
};
