import {tns} from 'tiny-slider/src/tiny-slider.js';
import 'tiny-slider/dist/tiny-slider.css';
import Accordion from './details-animation.js';
import DetailOnOff from './details-on-off.js';

const TABLET_WIDTH = 768;

const SLIDER_SETTINGS = {
  container: '.old-products__list',
  gutter: 40,
  slideBy: 1,
  speed: 400,
  loop: true,
  mouseDrag: false,
  nav: false,
  autoplay: false,
  autoplayButtonOutput: false,
  controlsContainer: '.old-products__slider-controls',
  responsive: {
    768: {
      items: 2,
    },
    1440: {
      items: 3,
      mouseDrag: true,
    }
  }
};

const oldProductsSection = document.querySelector('.old-products');

let isSliderInit = false;
let slider;

const resetOldProductsDetails = () => {
  oldProductsSection.querySelectorAll('details').forEach((el) => {
    new Accordion(el);
    new DetailOnOff(el);
  });
};

const onWindowResize = () => {
  if (window.innerWidth < TABLET_WIDTH && isSliderInit === true) {
    if (slider) {
      slider.destroy();
    }
    resetOldProductsDetails();
    isSliderInit = false;
  }

  if (window.innerWidth >= TABLET_WIDTH && isSliderInit === false) {
    slider = tns(SLIDER_SETTINGS);
    resetOldProductsDetails();
    isSliderInit = true;
  }
};

onWindowResize();
window.addEventListener('resize', onWindowResize);
