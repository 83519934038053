const header = document.querySelector('.header');

const onLinkHeaderClick = (evt) => {
  if (evt.target.tagName === 'A') {
    const isThisPage = evt.target.href.includes(window.location.origin);
    const isContainHashtag = evt.target.href.includes('#') && !evt.target.href.endsWith('/#') && !evt.target.href.includes('/rh_output');
    if (isThisPage && isContainHashtag) {
      setTimeout(() => scrollBy(0, header.offsetHeight * -1), 0);
    }
  }
};

header.addEventListener('click', onLinkHeaderClick);
