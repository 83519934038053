import {openModal} from './modal';

const introductionSearchForm = document.querySelector('.introduction__site-search.search');
const introductionSearchInput = introductionSearchForm.querySelector('.search__input');
const introductionProductVersion = introductionSearchForm.querySelector('.search__filter-select[name="product-version"]');
const introductionProductType = introductionSearchForm.querySelector('.search__filter-select[name="product-type"]');
const introductionExactCheckbox = introductionSearchForm.querySelector('.control__input[name="exact-searching-text"]');
const introductionSearchReset = introductionSearchForm.querySelector('.search__reset-button');
const yandexSearchForm = document.querySelector('#yandex-search-form');

const resetIntroductionSearchInput = () => {
  introductionSearchInput.value = '';
  introductionSearchInput.focus();
};

introductionSearchReset.addEventListener('click', resetIntroductionSearchInput);

const adaptSearchValues = (values) => {
  const adaptedValues = {...values,
    'searchingText': values['searching-text'],
    'exactSearchingText': values['exact-searching-text'] === 'on',
    'productVersion': values['product-version'],
    'productType': values['product-type'],
  };

  delete adaptedValues['searching-text'];
  delete adaptedValues['exact-searching-text'];
  delete adaptedValues['product-version'];
  delete adaptedValues['product-type'];

  return adaptedValues;
};

const setSearchValuesToForm = ({searchingText, exactSearchingText, productVersion, productType}) => {
  introductionSearchInput.value = searchingText;
  introductionExactCheckbox.checked = exactSearchingText;
  introductionProductVersion.value = productVersion;
  introductionProductType.value = productType;
};

const convertToSearchString = ({searchingText, exactSearchingText, productVersion, productType}) => {
  let convertedSearchString = '';

  if(exactSearchingText === true) {
    convertedSearchString += `"${searchingText.replace(/\s+/g, ' ').trim()}"`;
  } else {
    const searchingWords = searchingText.split(' ');
    searchingWords.map((searchingWord) => {
      convertedSearchString += searchingWord.length ? ` +${searchingWord}` : '';
    });
  }

  switch (true) {
    case(productVersion === 'any' && productType === 'any'):
      convertedSearchString += ` ${window.location.protocol}doc.s-terra.ru/rh_output/*`;
      break;
    case(productVersion !== 'any' && productType === 'any'):
      convertedSearchString += ` url:"doc.s-terra.ru/rh_output/${productVersion}*"`;
      break;
    case(productVersion === 'any' && productType !== 'any'):
      convertedSearchString += ` inurl:"/${productType}/output" ${window.location.protocol}doc.s-terra.ru/rh_output/*`;
      break;
    case(productVersion !== 'any' && productType !== 'any'):
      convertedSearchString += ` url:"doc.s-terra.ru/rh_output/${productVersion}/${productType}*"`;
      break;
    default:
      convertedSearchString += '';
  }

  return convertedSearchString;
};

const convertToSearchValues = (searchString) => {
  const convertedSearchValues = {};

  const searchParts = searchString.trim().split(' ');
  let searchTextParts = searchParts.slice(0, -1);
  if (searchString.includes(' inurl:"/')) {
    searchTextParts = searchParts.slice(0, -2);
  }
  const searchingWords = searchTextParts.join(' ');

  if (/^"/.test(searchParts[0])) {
    convertedSearchValues.searchingText = searchingWords.slice(1, -1);
    convertedSearchValues.exactSearchingText = true;
  } else {
    convertedSearchValues.searchingText = searchingWords.replaceAll('+', '');
    convertedSearchValues.exactSearchingText = false;
  }


  let pathStart = '/rh_output/';
  if (searchString.includes(' inurl:"/')) {
    pathStart = ' inurl:"/';
  }
  const startIndexOfPath = searchString.indexOf(pathStart) + pathStart.length;
  const endIndexOfPath = searchString.length - 2;
  const searchPath = searchString.slice(startIndexOfPath, endIndexOfPath);
  const searchPaths = searchPath.split('/');

  switch (true) {
    case(searchString.includes(' url:')):
      convertedSearchValues.productVersion = searchPaths[0];
      convertedSearchValues.productType = searchPaths[1] ?? 'any';
      break;
    case(searchString.includes(' inurl:')):
      convertedSearchValues.productVersion = 'any';
      convertedSearchValues.productType = searchPaths[0];
      break;
    default:
      convertedSearchValues.productVersion = 'any';
      convertedSearchValues.productType = 'any';
  }

  return convertedSearchValues;
};

introductionSearchForm.addEventListener('submit', (evt) => {
  evt.preventDefault();

  if (introductionSearchInput.value.trim().length === 0) {
    return;
  }

  const formData = new FormData(evt.target);

  const formValues = {};
  [...formData.keys()].map((key) => {
    formValues[key] = formData.get(key);
  });

  const adaptedSearchValues = adaptSearchValues(formValues);
  const yandexSearchInput = yandexSearchForm.querySelector('input[type="search"]');
  const yandexSearchSubmitButton = yandexSearchForm.querySelector('.ya-site-form__submit');

  yandexSearchInput.value = convertToSearchString(adaptedSearchValues);
  yandexSearchSubmitButton.click();
});

const stopTimer = (timer) => {
  clearInterval(timer);
};

const getYandexSearchInputValue = () => {
  if (window.location.search.length === 0) {
    return;
  }
  openModal();

  let searchInputText = '';

  const searchInputTimer = setInterval(() => {
    const searchInput = document.querySelector('.ya-site-form__input-text');

    if (searchInputText.length === 0 && searchInput) {
      searchInputText = searchInput.value;
      stopTimer(searchInputTimer);
      setSearchValuesToForm(convertToSearchValues(searchInput.value));
    }
  }, 100);
};

window.addEventListener('DOMContentLoaded', getYandexSearchInputValue);
