const mainMenu = document.querySelector('.main-nav__wrapper');
const mainMenuButton = document.querySelector('.main-nav__toggle');
const mainMenuMask = document.querySelector('.main-nav__mask');
const noJS = document.querySelector('.main-nav');

noJS.classList.remove('main-nav--no-js');

function onLinkClick (evt) {
  if (evt.target.tagName === 'A') {
    closeMenu();
  }
}

function openMenu () {
  mainMenu.classList.add('main-nav__wrapper--opened');
  mainMenu.classList.remove('main-nav__wrapper--closed');

  mainMenuButton.classList.add('main-nav__toggle--opened');
  mainMenuButton.classList.remove('main-nav__toggle--closed');

  mainMenuMask.classList.add('main-nav__mask--opened');
  mainMenuMask.classList.remove('main-nav__mask--closed');

  document.body.classList.add('scroll-lock');
  mainMenuMask.addEventListener('click', closeMenu);
  mainMenu.addEventListener('click', onLinkClick);
}

function closeMenu () {
  mainMenu.classList.add('main-nav__wrapper--closed');
  mainMenu.classList.remove('main-nav__wrapper--opened');

  mainMenuButton.classList.add('main-nav__toggle--closed');
  mainMenuButton.classList.remove('main-nav__toggle--opened');

  mainMenuMask.classList.add('main-nav__mask--closed');
  mainMenuMask.classList.remove('main-nav__mask--opened');

  document.body.classList.remove('scroll-lock');
  mainMenuMask.removeEventListener('click', closeMenu);
  mainMenu.removeEventListener('click', onLinkClick);
}

mainMenuButton.addEventListener('click', () => {
  if (mainMenuButton.classList.contains('main-nav__toggle--closed')) {
    openMenu();
  } else {
    closeMenu();
  }
});
