const regulationsSection = document.querySelector('.regulations');
const regulationsTypeLabels = regulationsSection.querySelector('.regulations__labels');
const regulationsTypeItems = regulationsSection.querySelectorAll('.regulations__types-item');

const regulationsVersionsSelect = regulationsSection.querySelector('.regulations__filter-select');
const regulationsVersionItems = regulationsSection.querySelectorAll('.regulations__ver-item');

const noRegulationsMessageTemplate = document.querySelector('#no-regulations')
  .content
  .querySelector('.no-regulations');
const noRegulationsMessageContaner = regulationsSection.querySelector('.regulations__types-list');

const itemsClassToggle = (ladle, items, showClass, dataname) => {
  items.forEach((item) => {
    if (ladle.value === item.dataset[dataname]) {
      item.classList.add(showClass);
    } else {
      item.classList.remove(showClass);
    }
  });
};


let checkedRegulationsType = regulationsTypeLabels.querySelector('.regulations__type-input:checked').value;
let checkedRegulationsVersion = regulationsVersionsSelect.value;

const addNoRegulationsMessage = () => noRegulationsMessageContaner.append(noRegulationsMessageTemplate);
const removeNoRegulationsMessage = () => {
  const noRegulationsMessage = noRegulationsMessageContaner.querySelector('.no-regulations');
  if (noRegulationsMessage) {
    noRegulationsMessage.remove();
  }
};

const showNoRegulationsMessage = () => {
  let ok = false;

  const typeItems = Object.values(regulationsTypeItems);
  const typeItem = typeItems.filter((item) => item.dataset.type === checkedRegulationsType);
  const versionItem = typeItem[0].querySelector(`.regulations__ver-item[data-version="${checkedRegulationsVersion}"]`);

  if (!versionItem && ok === false) {
    ok = true;
    addNoRegulationsMessage();
  } else {
    ok = false;
    removeNoRegulationsMessage();
  }
};

const onRegulationsTypeChange = (evt) => {
  itemsClassToggle(evt.target, regulationsTypeItems, 'regulations__types-item--show', 'type');
  checkedRegulationsType = evt.target.value;
  showNoRegulationsMessage();
};

const onRegulationsVersionChange = (evt) => {
  itemsClassToggle(evt.target, regulationsVersionItems, 'regulations__ver-item--show', 'version');
  checkedRegulationsVersion = evt.target.value;
  showNoRegulationsMessage();
};

regulationsTypeLabels.addEventListener('change', onRegulationsTypeChange);
regulationsVersionsSelect.addEventListener('change', onRegulationsVersionChange);
