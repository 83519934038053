import {createElement} from '../render.js';

function createSnowTemplate() {
  return (`
  <div class="snow">
    <div class="snow__large"></div>
    <div class="snow__medium"></div>
    <div class="snow__small"></div>
  </div>
  `);
}

export default class SnowView {
  getTemplate() {
    return createSnowTemplate();
  }

  getElement() {
    if (!this.element) {
      this.element = createElement(this.getTemplate());
    }

    return this.element;
  }

  removeElement() {
    this.element = null;
  }
}
