const scenariosSection = document.querySelector('.scenarios');
const scenariosDetails = scenariosSection.querySelector('.scenarios__details');

const pdfDetails = document.querySelector('.pdf-doc__details');
const oldProductsDetails = document.querySelectorAll('.old-products__details');

const regulationsSection = document.querySelector('.regulations');
const regulationsTypeDetails = regulationsSection.querySelectorAll('.regulations__type-details');
const regulationsVerDetails = regulationsSection.querySelectorAll('.regulations__ver-details');


const TABLET_WIDTH = 768;

export default class DetailOnOff {
  #details = null;
  #summary = null;
  #isMobile = false;

  constructor(details) {
    this.#details = details;
    this.#summary = details.querySelector('summary');

    this.init();
  }

  init() {
    if (window.innerWidth < TABLET_WIDTH && !this.#isMobile) {
      this.#unblockDetails();
      this.#isMobile = true;
    }

    if (window.innerWidth >= TABLET_WIDTH) {
      this.#blockDetails();
      this.#isMobile = false;
    }
  }

  #unblockDetails = () => {
    this.#details.removeAttribute('open');
    this.#summary.classList.remove('no-pointer-events');
  };

  #blockDetails = () => {
    this.#details.setAttribute('open', '');
    this.#summary.classList.add('no-pointer-events');
  };
}

const DetailOnOffPresenters = new Map();

const saveToDetaisPresenters = (details) => {
  details.forEach((detailsElement) => {
    DetailOnOffPresenters.set(DetailOnOffPresenters.size + 1, new DetailOnOff(detailsElement));
  });
};

saveToDetaisPresenters ([scenariosDetails]);
saveToDetaisPresenters ([pdfDetails]);
saveToDetaisPresenters (regulationsTypeDetails);
saveToDetaisPresenters (regulationsVerDetails);
saveToDetaisPresenters (oldProductsDetails);

oldProductsDetails.forEach((el) => {
  new DetailOnOff(el);
});

const initAllDetaisPresenters = () => DetailOnOffPresenters.forEach((presenter) => presenter.init());
window.addEventListener('resize', initAllDetaisPresenters);
