import {render, RenderPosition} from './render.js';
import SnowView from './view/snow-view.js';

const copyrightYear = document.querySelector('.footer__copyright-year');

const nowDate = new Date();

const setFooterCopyrightYear = () => {
  copyrightYear.textContent = nowDate.getFullYear();
};

const setNewYearThem = () => {
  const date = nowDate.getUTCDate();
  const month = nowDate.getUTCMonth() + 1;

  if ((month === 1 && date <= 10) || (month === 12 && date >= 10)) {
    render(new SnowView(), document.body, RenderPosition.AFTERBEGIN);
  }
};

setFooterCopyrightYear();
setNewYearThem();
